import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AlertController,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonProgressBar,
  IonSearchbar,
} from '@ionic/angular/standalone';
import { Api } from '../../apollo/api';
import { FormControl, Validators } from '@angular/forms';
import { Entry, EntryProposes, EntrySearchItem, Maybe } from '../../../base-types.gql-gen';
import { ProviderCardComponent } from '../providers-card/provider-card.component';
import { declension, phoneFormatUniversal, phoneNumberValidator } from '../../utils';

@Component({
  selector: 'app-providers-list',
  templateUrl: 'providers-list.component.html',
  styleUrls: ['./providers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonProgressBar,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    ProviderCardComponent,
  ],
})
export class ProvidersListComponent implements OnInit {
  loading = true;
  page = 1;
  size = 10;
  searchStarted = false;
  searchText = '';
  totalCount = 0;
  maxPages = 1;
  providersForView: Maybe<EntrySearchItem>[] = [];
  storedResult: Maybe<EntrySearchItem>[] = [];
  fullResult?: Maybe<EntrySearchItem>[] = [];
  phoneNumber = new FormControl('+7', [Validators.required, phoneNumberValidator()]);
  @Output() showCancelModal = new EventEmitter();
  @Input() entry!: Entry;
  constructor(
    private api: Api,
    private cd: ChangeDetectorRef,
    private alertCtrl: AlertController,
  ) {}

  ngOnInit() {
    this.getData(this.page, this.size);
  }

  trackByForProviders(_index: any, item: any) {
    return item.organization.uuid;
  }

  async onIonInfinite(ev: any) {
    this.page = ++this.page;
    await this.getData(this.page, this.size);
    ev.target.complete();
  }

  onShowCancelModal(propose: Maybe<EntryProposes>) {
    this.showCancelModal.emit(propose);
  }

  resetProviders(page: number, size: number) {
    this.providersForView = [];
    return this.getData(page, size);
  }

  getData(page: number, size: number) {
    this.loading = true;
    return this.api
      .getPartnersForEntry(this.entry.uuid, page, size)
      .result()
      .then((res: any) => {
        this.cd.markForCheck();
        const data = res?.data?.entry?.searchResult;
        this.maxPages = data?.paginationInfo?.pageCount;
        this.providersForView = this.providersForView.concat(data?.items);
        this.storedResult = this.providersForView;
        this.loading = false;
      });
  }

  setSearchText(query: string) {
    const length = this.providersForView.length;
    const text = declension(length, 'совпадение', 'совпадения', 'совпадений');
    this.searchText = `По запросу «${query}» найдено ${length} ${text}:`;
  }

  async search(event: any) {
    this.cd.markForCheck();
    const query = event.target.value.toLowerCase();
    if (!query) {
      this.providersForView = this.storedResult;
      this.searchStarted = false;
      return;
    }
    this.searchStarted = true;
    if (!this.fullResult) {
      this.loading = true;
      await this.api
        .getPartnersForEntry(this.entry.uuid)
        .result()
        .then((res: any) => {
          this.cd.markForCheck();
          this.fullResult = res?.data?.entry?.searchResult?.items;
          this.totalCount = res?.data?.entry?.searchResult?.paginationInfo?.totalResultsCount;
        });
      this.loading = false;
    }
    this.providersForView = !this.fullResult
      ? []
      : this.fullResult.filter((item: any) => {
          const company = item.company;
          const organization = item.organization;
          const companies = organization.companies;

          return !![
            company?.name?.toLocaleLowerCase(),
            organization?.name?.toLocaleLowerCase(),
            ...companies?.map((c: any) => c?.name?.toLocaleLowerCase()),
            ...companies?.map((c: any) => c?.requisite?.inn?.toLocaleLowerCase()),
            ...organization?.users?.map((user: any) => user?.user?.firstname?.toLocaleLowerCase()),
            ...organization?.users?.map((user: any) => user?.user?.lastname?.toLocaleLowerCase()),
            ...organization?.users?.map((user: any) => user?.user?.phone?.toLocaleLowerCase()),
          ].filter((matchItem) => matchItem?.includes(query)).length;
        });
    this.setSearchText(query);
  }

  async btnInvite_Click() {
    const alert = await this.alertCtrl.create({
      header: 'Пригласить исполнителя',
      subHeader:
        'Мы отправим Исполнителю сообщение в WhatsApp со ссылками на регистрацию на сайте и в мобильном приложении.',
      inputs: [
        {
          id: 'invite_phone',
          type: 'tel',
          label: 'Телефон',
          placeholder: '+7(xxx)xxx-xx-xx',
          value: this.phoneNumber.value,
          attributes: {
            maxlength: 18,
            oninput: (e: any) => {
              this.phoneNumber.setValue(phoneFormatUniversal(e.target.value), { emitEvent: false });
              // hack to rerender alert input value
              const mock = { ...alert.inputs[0], value: this.phoneNumber.value };
              alert.inputs = [mock];
            },
          },
        },
      ],
      buttons: [
        {
          text: 'Отмена',
          handler: () => {},
        },
        {
          text: 'Отправить',
          handler: () => {
            if (!this.phoneNumber.valid) {
              alert.message = `<p class="alert-message-error">Введите номер в формате</p>
                 <p class="alert-message-error">+7(xxx)xxx-xx-xx</P>`;
              return false;
            }
            const phone = (this.phoneNumber.value as string).replace(/\D/g, '');
            const msg = {
              from: 'mobappV2',
              cmd: 'send-invite',
              phone,
            };
            window.parent.postMessage(msg, '*');
            return true;
          },
        },
      ],
      mode: 'ios',
      animated: true,
    });
    await alert.present();
  }
}
