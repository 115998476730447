@if (userFirstName !== undefined) {
  <ion-header>
    <ion-toolbar></ion-toolbar>
  </ion-header>
  <ion-content class="ion-no-padding ion-padding-horizontal">
    <p>
      @if (userFirstName) {
        <b>{{ userFirstName }}</b>
        <span>
          , рады видеть вас в обновленном Рентал Клаб - сервисе для быстрой и безопасной аренды
          спецтехники.
        </span>
      } @else {
        <span>
          Рады видеть вас в обновленном Рентал Клаб - сервисе для быстрой и безопасной аренды
          спецтехники.
        </span>
      }
    </p>
    <p>
      Теперь сервис доступен как в мобильном приложении, так и в
      <a href="https://lk.rental-club.ru/auth/login" target="_blank">личном кабинете</a>
      <span>.</span>
    </p>
    <p>
      <b>Если вы заказчик:</b>
    </p>
    <ul>
      <li>
        Подбирайте технику в аренду у проверенных поставщиков, используя каталог, карту и автопоиск.
      </li>
      <li>Подписывайте заявку с поставщиком для фиксации цены и условий работы.</li>
      <li>Оценивайте работу техники по факту выполнения заказа.</li>
    </ul>
    <p>
      <b>Если вы поставщик:</b>
    </p>
    <ul>
      <li>
        Получайте заявки именно на вашу технику от диспетчерских компаний, конечных заказчиков, а
        так же заявки из внешних источников.
      </li>
      <li>
        Получайте гарантированную оплату на определенный тип заказов от партнеров Рентал Клаб.
      </li>
    </ul>
    <p>Пишите нам или звоните, если есть вопросы по работе сервиса. Мы на связи!</p>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-button
      (click)="onContinueButtonClick($event)"
      expand="block"
      class="ion-padding"
      size="large"
      color="rcyellow"
    >
      <b>Всё понятно!</b>
    </ion-button>
  </ion-footer>
}
