@if (showModal) {
  <ion-backdrop [tappable]="true" (ionBackdropTap)="closeModal()"></ion-backdrop>
  <div id="box">
    <div class="text-info"><p>Подписанная заявка с поставщиком будет отменена. Продолжить?</p></div>
    @if (loading) {
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    }
    <div class="buttons-wrapper">
      <ion-button class="ion-margin-start" color="light" (click)="closeModal()">
        Отменить
      </ion-button>
      <ion-button class="cancel" (click)="cancelSign()">Да</ion-button>
    </div>
  </div>
}
