import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { WelcomePageAuthCurrentUserQueryGql } from './welcome-page.component.gql-gen';
import { Subscription } from 'rxjs';
import { assertIsDefined } from '../utils';

@Component({
  selector: 'app-welcome-page',
  standalone: true,
  imports: [IonContent, IonButton, IonFooter, IonToolbar, IonHeader],
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  protected userFirstName?: string;
  private sub?: Subscription;
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = '*'; // todo: use proper origin

  constructor(
    private authCurrentUserQueryGql: WelcomePageAuthCurrentUserQueryGql,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.sub = this.authCurrentUserQueryGql.fetch().subscribe({
      next: (res) => {
        assertIsDefined(res.data.authCurrentUser);
        this.userFirstName = res.data.authCurrentUser.firstname ?? '';
        this.cdRef.markForCheck();
      },
      error: () => {
        this.userFirstName = '';
      },
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onContinueButtonClick(ev: Event) {
    ev.preventDefault();
    const msg = {
      from: 'mobappV2',
      cmd: 'navigateToDefaultPage',
    };
    this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
  }
}
