<ion-header>
  <ion-toolbar>
    <ion-title>Выберите тип техники</ion-title>
    <ion-buttons slot="start">
      <ion-button
        class="ion-margin-vertical"
        shape="round"
        size="medium"
        slot="end"
        (click)="onBackButtonClick($event)"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list>
    @for (item of items; track item.id) {
      <ion-item
        (click)="onMachineTypeClick($event, $index)"
        class="ion-no-padding"
        button
        detail="true"
      >
        <ion-label>
          <span>{{ item.nameRU }}</span>
        </ion-label>
      </ion-item>
    }
  </ion-list>
</ion-content>
