import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import {
  MachineTypeSelectionModalMachineTypesQuery,
  MachineTypeSelectionModalMachineTypesQueryGql,
} from './machine-type-selection-modal.gql-gen';
import { firstValueFrom } from 'rxjs';
import { assertIsDefined } from '../utils';
import { ApolloQueryResult } from '@apollo/client';

export type MachineTypeX = NonNullable<
  NonNullable<
    ApolloQueryResult<MachineTypeSelectionModalMachineTypesQuery>['data']['machineTypes']
  >[number]
>;

export type RcSelectEvent = {
  cause: Event;
  data: MachineTypeX;
};

@Component({
  selector: 'app-machine-type-selection-modal',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonButton,
  ],
  templateUrl: './machine-type-selection-modal.component.html',
  styleUrl: './machine-type-selection-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineTypeSelectionModalComponent implements OnInit {
  @Output() rcSelect = new EventEmitter<RcSelectEvent>();
  @Output() rcClose = new EventEmitter<unknown>();

  protected items: MachineTypeX[] = [];

  constructor(
    private machineTypesQueryGql: MachineTypeSelectionModalMachineTypesQueryGql,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const res = await firstValueFrom(this.machineTypesQueryGql.fetch());
    const { machineTypes } = res.data;
    assertIsDefined(machineTypes); // if machine types are not found by id - it is error
    this.items = [];
    for (const mt of machineTypes) {
      if (mt) {
        this.items.push(mt);
      }
    }
    this.items.sort((a, b) => {
      if (a.nameRU > b.nameRU) {
        return 1;
      }
      if (a.nameRU < b.nameRU) {
        return -1;
      }
      return 0;
    });
    this.cdRef.detectChanges();
  }

  onMachineTypeClick(ev: Event, idx: number) {
    ev.preventDefault();
    const item = this.items[idx];
    this.rcSelect.emit({ cause: ev, data: item });
  }

  onBackButtonClick(ev: Event) {
    ev.preventDefault();
    this.rcClose.emit();
  }
}
