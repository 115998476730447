import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AlertController,
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { InfiniteScrollCustomEvent, RefresherCustomEvent, ToggleCustomEvent } from '@ionic/angular';
import dayjs from 'dayjs';
import { assertIsDefined, isDefined, translateWorkAmount } from '../../utils';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  EnumEntryRequestsConditionsDateType,
  EnumEntryRequestsConditionsWorkType,
} from '../../../base-types.gql-gen';
import { MaskitoOptions, maskitoTransform } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import {
  MatchesTabAuthCurrentUserQueryGql,
  MatchesTabEntryClosedEntriesQueryGql,
  MatchesTabOrdersQueryGql,
  MatchesTabSearchEntryMatchesQueryGql,
} from './matches-tab.component.gql-gen';
import { switchMap } from 'rxjs/operators';

type SelectedView = 'activeMatches' | 'inactiveMatches' | 'fakeOrders';

type EntryMatchUIData = {
  id: string;
  entryMatchDetailsQueryParams: Record<string, string>;
  status: string;
  isProposed: boolean;
  waitingForSign: boolean;
  machineTypeNameRu: string;
  isWDToday: boolean;
  workDateTime: string;
  workAmountUnit: string;
  distanceToBaseKm: number;
  organizationName: string;
  isOrganizationVerified: boolean;
  userFullName: string;
  viewed: boolean;
  avatarURL: string;
  foundAgo: string;
  foundProgress: number;
  msgComment: string;
  msgUserName: string;
};

type FakeOrderUIData = {
  id: string;
  orderDetailsQueryParams: Record<string, string>;
  machineTypeNameRu: string;
  machineModel: string;
  machineMainParam: string;
  avatarURL: string;
  organizationName: string;
  isVerified: boolean;
  userFullName: string;
  address: string;
  workDateTime: string;
  workAmountUnit: string;
  price: string;
  payment: string;
};

type EntryClosedUIData = {
  id: string;
  entryClosedDetailsQueryParams: Record<string, string>;
  machineTypeId: string;
  machineTypeNameRu: string;
  distanceToBaseKm: string;
  workDateTime: string;
  workAmountUnit: string;
  organizationName: string;
  userFullName: string;
  avatarURL: string;
  foundAgo: string;
  msgComment: string;
  msgUserName: string;
};

let fakeIdInc = 0;

@Component({
  selector: 'app-matches-tab',
  templateUrl: 'matches-tab.component.html',
  styleUrls: ['matches-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonToggle,
    IonItem,
    IonList,
    IonIcon,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonAvatar,
    IonNote,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonImg,
    IonProgressBar,
    RouterLink,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonBadge,
    IonChip,
  ],
})
export class MatchesTabComponent implements OnInit, OnDestroy {
  protected selectedSegment: SelectedView = 'activeMatches';
  protected currentView: SelectedView = 'activeMatches';

  protected activeMatchesState = {
    pending: false,
    hasNext: true,
    skip: 0,
    items: [] as EntryMatchUIData[],
    sub: undefined as Subscription | undefined,
    refreshTarget: undefined as HTMLIonRefresherElement | undefined,
    infinityScrollTarget: undefined as HTMLIonInfiniteScrollElement | undefined,
  };

  protected inactiveMatchesState = {
    pending: false,
    hasNext: true,
    skip: 0,
    items: [] as EntryClosedUIData[],
    sub: undefined as Subscription | undefined,
    myMachineTypeIds: new Set<string>(),
    refreshTarget: undefined as HTMLIonRefresherElement | undefined,
    infinityScrollTarget: undefined as HTMLIonInfiniteScrollElement | undefined,
  };

  protected fakeOrdersState = {
    pending: false,
    hasNext: true,
    page: 1,
    items: [] as FakeOrderUIData[],
    sub: undefined as Subscription | undefined,
    refreshTarget: undefined as HTMLIonRefresherElement | undefined,
    infinityScrollTarget: undefined as HTMLIonInfiniteScrollElement | undefined,
  };

  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });

  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = '*'; // todo: use proper origin
  private sub?: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertController: AlertController,
    private entryMatchesQueryGql: MatchesTabSearchEntryMatchesQueryGql,
    private closedEntriesQueryGql: MatchesTabEntryClosedEntriesQueryGql,
    private fakeOrdersQueryGql: MatchesTabOrdersQueryGql,
    private authCurrentUserQueryGql: MatchesTabAuthCurrentUserQueryGql,
  ) {}

  ngOnInit() {
    this.sub = this.activatedRoute.queryParams.subscribe((params) => {
      if (params['segment'] === 'activeMatches') {
        this.selectedSegment = 'activeMatches';
        this.currentView = 'activeMatches';
      }
      if (params['segment'] === 'inactiveMatches') {
        this.selectedSegment = 'activeMatches';
        this.currentView = 'inactiveMatches';
      }
      if (params['segment'] === 'fakeOrders') {
        this.selectedSegment = 'fakeOrders';
        this.currentView = 'fakeOrders';
      }
      this.loadCurrentViewData();
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.activeMatchesState.sub?.unsubscribe();
    this.inactiveMatchesState.sub?.unsubscribe();
  }

  onSegmentChange(event: CustomEvent) {
    this.selectedSegment = event.detail.value;
    this.currentView = this.selectedSegment;
    this.loadCurrentViewData();
  }

  onActiveMatchesToggleChange(ev: ToggleCustomEvent) {
    ev.preventDefault();
    if (ev.detail.checked) {
      this.currentView = 'activeMatches';
    } else {
      this.currentView = 'inactiveMatches';
    }
    this.loadCurrentViewData();
  }

  async onActiveMatchesRefresh(ev: RefresherCustomEvent) {
    ev.preventDefault();
    await this.activeMatchesState.refreshTarget?.complete();
    this.activeMatchesState.refreshTarget = ev.target;
    this.loadActiveMatches('new');
  }

  async onActiveMatchesInfiniteScrollInfinite(ev: InfiniteScrollCustomEvent) {
    ev.preventDefault();
    await this.activeMatchesState.infinityScrollTarget?.complete();
    this.activeMatchesState.infinityScrollTarget = ev.target;
    this.loadActiveMatches('next');
  }

  async onInactiveMatchesRefresh(ev: RefresherCustomEvent) {
    ev.preventDefault();
    await this.inactiveMatchesState.refreshTarget?.complete();
    this.inactiveMatchesState.refreshTarget = ev.target;
    this.loadInactiveMatches('new');
  }

  async onInactiveMatchesInfiniteScrollInfinite(ev: InfiniteScrollCustomEvent) {
    ev.preventDefault();
    await this.inactiveMatchesState.infinityScrollTarget?.complete();
    this.inactiveMatchesState.infinityScrollTarget = ev.target;
    this.loadInactiveMatches('next');
  }

  async onFakeOrdersRefresh(ev: RefresherCustomEvent) {
    ev.preventDefault();
    await this.fakeOrdersState.refreshTarget?.complete();
    this.fakeOrdersState.refreshTarget = ev.target;
    this.loadFakeOrders('new');
  }

  async onFakeOrdersInfiniteScrollInfinite(ev: InfiniteScrollCustomEvent) {
    ev.preventDefault();
    await this.fakeOrdersState.infinityScrollTarget?.complete();
    this.fakeOrdersState.infinityScrollTarget = ev.target;
    this.loadFakeOrders('next');
  }

  private loadCurrentViewData() {
    if (this.currentView === 'activeMatches') {
      this.loadActiveMatches('new');
    }
    if (this.currentView === 'inactiveMatches') {
      this.loadInactiveMatches('new');
    }
    if (this.currentView === 'fakeOrders') {
      this.loadFakeOrders('new');
    }
  }

  private loadActiveMatches(action: 'new' | 'next') {
    const BATCH_SIZE = 10;
    if (action === 'new') {
      this.activeMatchesState.skip = 0;
      this.activeMatchesState.hasNext = true;
      this.activeMatchesState.items = [];
    }
    if (!this.activeMatchesState.hasNext) {
      return;
    }
    this.activeMatchesState.pending = true;
    this.activeMatchesState.sub?.unsubscribe();
    this.activeMatchesState.sub = this.entryMatchesQueryGql
      .fetch({
        skip: this.activeMatchesState.skip,
        limit: BATCH_SIZE,
      })
      .subscribe(async (res) => {
        assertIsDefined(res.data.searchEntryMatches);
        assertIsDefined(res.data.authCurrentUser);
        if (res.data.searchEntryMatches.items.length < BATCH_SIZE) {
          this.activeMatchesState.hasNext = false;
        }
        const ONE_HOUR_SEC = 3600;
        for (const entryMatch of res.data.searchEntryMatches.items) {
          const { dispatcher, customer, request, status, distanceToBaseKm } = entryMatch;
          const machineTypeNameRu = request.machineName ?? '';

          let userFullName = '';
          let avatarURL = '';
          let foundAgo = '';
          const dateFound = dayjs(entryMatch.found);
          const duration = dayjs.duration(dayjs().diff(dateFound));
          if (duration.days() > 0) {
            foundAgo = `${duration.days()}д ${duration.hours()}ч`;
          } else if (duration.hours() > 0) {
            foundAgo = `${duration.hours()}ч ${duration.minutes()}м`;
          } else {
            foundAgo = `${duration.minutes()}м`;
          }

          const foundProgress = (dayjs().unix() - dayjs(entryMatch.found).unix()) / ONE_HOUR_SEC;

          let msgComment = entryMatch.additional?.customerText ?? '';
          let msgUserName = entryMatch.additional?.senderName ?? '';

          let organizationName = '';
          let isOrganizationVerified = false;
          const buyer = dispatcher || customer;
          if (buyer?.company) {
            organizationName = buyer.company.name;
            isOrganizationVerified = buyer.company.isVerified;
          }
          if (buyer?.user) {
            userFullName = buyer.user.firstname + ' ' + buyer.user.lastname;
          }
          if (buyer?.organization?.logo) {
            avatarURL = buyer.organization.logo;
          } else if (buyer?.user?.avatar) {
            avatarURL = buyer.user.avatar;
          }
          let isWDToday = false;
          let workDateTime = '';
          if (request.conditions?.date) {
            const x = this.translateWorkDateTime(request.conditions.date, true);
            workDateTime = x.workDateTime;
            isWDToday = x.isWDToday;
          }
          let workAmountUnit = '';
          if (request.conditions?.work) {
            workAmountUnit = this.translateWorkAmount(request.conditions.work);
          }

          this.activeMatchesState.items.push({
            id: entryMatch.id,
            isProposed: entryMatch.isProposed,
            waitingForSign: entryMatch.waitingForSign,
            machineTypeNameRu,
            organizationName,
            isOrganizationVerified,
            userFullName,
            workDateTime,
            isWDToday,
            workAmountUnit,
            status,
            distanceToBaseKm,
            foundAgo,
            foundProgress,
            viewed: entryMatch.viewed,
            avatarURL,
            msgComment,
            msgUserName,
            entryMatchDetailsQueryParams: {
              entryId: entryMatch.id,
              viewAs: 'supplier',
              currentUserId: res.data.authCurrentUser.uuid,
            },
          });
        }

        this.activeMatchesState.skip += BATCH_SIZE;
        this.activeMatchesState.pending = false;

        await this.activeMatchesState.refreshTarget?.complete();
        this.activeMatchesState.refreshTarget = undefined;
        await this.activeMatchesState.infinityScrollTarget?.complete();
        this.activeMatchesState.infinityScrollTarget = undefined;

        this.cdRef.markForCheck();
      });
  }

  private loadInactiveMatches(action: 'new' | 'next') {
    const BATCH_SIZE = 10;
    if (action === 'new') {
      this.inactiveMatchesState.skip = 0;
      this.inactiveMatchesState.hasNext = true;
      this.inactiveMatchesState.items = [];
    }
    if (!this.inactiveMatchesState.hasNext) {
      return;
    }
    this.inactiveMatchesState.pending = true;
    this.inactiveMatchesState.sub?.unsubscribe();
    this.inactiveMatchesState.sub = this.closedEntriesQueryGql
      .fetch({
        skip: this.inactiveMatchesState.skip,
        limit: BATCH_SIZE,
      })
      .subscribe(async (res) => {
        if (
          res.data.entryClosedEntries?.items &&
          res.data.entryClosedEntries.items.length < BATCH_SIZE
        ) {
          this.inactiveMatchesState.hasNext = false;
        }

        for (const closedMatch of res.data.entryClosedEntries?.items ?? []) {
          if (closedMatch) {
            const { id, requests, customer, dispatcher, date, additional } = closedMatch;
            let organizationName = '';
            let userFullName = '';
            let avatarURL = '';

            let foundAgo = '';
            if (date?.created) {
              const dateFound = dayjs(date.created * 1000);
              const duration = dayjs.duration(dayjs().diff(dateFound));
              if (duration.days() > 0) {
                foundAgo = `${duration.days()}д ${duration.hours()}ч`;
              } else if (duration.hours() > 0) {
                foundAgo = `${duration.hours()}ч ${duration.minutes()}м`;
              } else {
                foundAgo = `${duration.minutes()}м`;
              }
            }

            // if (date?.created) {
            //   foundAgo = dayjs(date.created * 1000).fromNow();
            // }

            const buyer = dispatcher || customer;
            if (buyer?.organization) {
              organizationName = buyer.organization.name ?? '';
            }
            if (buyer?.user) {
              userFullName = buyer.user.firstname + ' ' + buyer.user.lastname;
            }
            if (buyer?.organization?.logo) {
              avatarURL = buyer.organization.logo;
            } else if (buyer?.user?.avatar) {
              avatarURL = buyer.user.avatar;
            }

            let machineTypeId = '';
            let machineTypeNameRu = '';
            let workDateTime = '';
            let workAmountUnit = '';

            const request = requests.at(0);
            if (request?.data?.machineTypeData) {
              machineTypeId = request.data.machineTypeData.id;
              machineTypeNameRu = request.data.machineTypeData.nameRU;
            }
            if (request?.conditions?.date) {
              const x = this.translateWorkDateTime(request.conditions.date, false);
              workDateTime = x.workDateTime;
            }
            if (request?.conditions?.work) {
              workAmountUnit = this.translateWorkAmount(request.conditions.work);
            }

            let msgComment = '';
            if (additional?.customerText) {
              msgComment = additional.customerText;
            }
            let msgUserName = '';
            if (additional?.senderName) {
              msgUserName = additional.senderName;
            }
            let distanceToBaseKm = '';
            if (closedMatch.distanceToBaseKm) {
              distanceToBaseKm = `${closedMatch.distanceToBaseKm}`;
            }

            this.inactiveMatchesState.items.push({
              id,
              entryClosedDetailsQueryParams: {
                entryId: closedMatch.uuid,
              },
              machineTypeId,
              machineTypeNameRu,
              distanceToBaseKm,
              organizationName,
              userFullName,
              avatarURL,
              foundAgo,
              workDateTime,
              workAmountUnit,
              msgComment,
              msgUserName,
            });
          }
        }

        for (const organization of res.data.authCurrentUser?.organizations ?? []) {
          for (const machine of organization?.machines ?? []) {
            if (machine) {
              this.inactiveMatchesState.myMachineTypeIds.add(machine.typeData.id);
            }
          }
        }
        this.inactiveMatchesState.skip += BATCH_SIZE;
        this.inactiveMatchesState.pending = false;

        await this.inactiveMatchesState.refreshTarget?.complete();
        this.inactiveMatchesState.refreshTarget = undefined;
        await this.inactiveMatchesState.infinityScrollTarget?.complete();
        this.inactiveMatchesState.infinityScrollTarget = undefined;
        this.cdRef.markForCheck();
      });
  }

  private loadFakeOrders(action: 'new' | 'next') {
    const BATCH_SIZE = 10;
    if (action === 'new') {
      this.fakeOrdersState.page = 1;
      this.fakeOrdersState.hasNext = true;
      this.fakeOrdersState.items = [];
    }
    if (!this.fakeOrdersState.hasNext) {
      return;
    }
    this.fakeOrdersState.pending = true;
    this.fakeOrdersState.sub?.unsubscribe();
    this.fakeOrdersState.sub = this.authCurrentUserQueryGql
      .fetch()
      .pipe(
        switchMap((res) => {
          assertIsDefined(res.data.authCurrentUser);
          let organizationUuid = '';
          for (const org of res.data.authCurrentUser.organizations) {
            assertIsDefined(org);
            organizationUuid = org.uuid;
          }
          return this.fakeOrdersQueryGql.fetch({
            organizationUuid,
            page: this.fakeOrdersState.page,
            size: BATCH_SIZE,
          });
        }),
      )
      .subscribe({
        next: async (res) => {
          assertIsDefined(res.data.orders);
          assertIsDefined(res.data.authCurrentUser);
          if (res.data.orders.items && res.data.orders.items.length < BATCH_SIZE) {
            this.fakeOrdersState.hasNext = false;
          }
          for (const fakeOrder of res.data.orders.items ?? []) {
            if (fakeOrder) {
              for (const prop of fakeOrder.proposes ?? []) {
                if (prop) {
                  const { id, responses, objects, dispatcher, customer, requests } = prop;
                  const fakeId = `${id}_${fakeIdInc++}`;
                  const firstRequest = requests?.at(0);
                  const firstResponse = responses?.at(0);
                  const firstObject = objects?.at(0);

                  let address = '';
                  let payment = '';
                  let price = '';

                  let workDateTime = '';
                  let workAmountUnit = '';
                  if (firstRequest?.conditions?.date) {
                    const x = this.translateWorkDateTime(firstRequest.conditions.date, false);
                    workDateTime = x.workDateTime;
                  }
                  if (firstRequest?.conditions?.work) {
                    workAmountUnit = this.translateWorkAmount(firstRequest.conditions.work);
                  }
                  if (firstResponse?.data?.paymentType) {
                    if (firstResponse.data.paymentType === 'none') {
                      payment = '';
                    }
                    if (firstResponse.data.paymentType === 'cash') {
                      payment = 'наличный расчет';
                    }
                    if (firstResponse.data.paymentType === 'cashless') {
                      payment = 'без НДС';
                    }
                    if (firstResponse.data.paymentType === 'cashless_with_taxes') {
                      payment = 'с НДС';
                    }
                    if (isDefined(firstResponse.data.budgetValue)) {
                      price =
                        maskitoTransform(
                          firstResponse.data.budgetValue / 100 + '',
                          this.priceMask,
                        ) + `₽`;
                      if (firstRequest?.conditions?.work?.type === 'cbm') {
                        price = price + '/куб';
                      }
                      if (firstRequest?.conditions?.work?.type === 'trip') {
                        price = price + '/рейс';
                      }
                      if (firstRequest?.conditions?.work?.type === 'shift') {
                        price = price + '/смена';
                      }
                    }
                  }

                  let organizationName = '';
                  let isVerified = false;
                  let userFullName = '';
                  let avatarURL = '';

                  if (dispatcher) {
                    const { companyForSupplier, user, organization } = dispatcher;
                    if (companyForSupplier) {
                      organizationName = companyForSupplier.name;
                      isVerified = companyForSupplier.verify ?? false;
                    }
                    if (user) {
                      userFullName = user.firstname + ' ' + user.lastname;
                    }
                    if (organization.logo) {
                      avatarURL = organization.logo;
                    } else if (user?.avatar) {
                      avatarURL = user.avatar;
                    }
                  } else if (customer) {
                    const { company, user, organization } = customer;
                    if (company) {
                      organizationName = company.name;
                      isVerified = company.verify ?? false;
                    }
                    if (user) {
                      userFullName = user.firstname + ' ' + user.lastname;
                    }
                    if (organization.logo) {
                      avatarURL = organization.logo;
                    } else if (user?.avatar) {
                      avatarURL = user.avatar;
                    }
                  }

                  if (firstObject?.address) {
                    address = firstObject.address.fullAddress ?? '';
                  }

                  let machineTypeNameRu = '';
                  let machineModel = '';
                  let machineMainParam = '';
                  if (firstResponse?.machine) {
                    machineTypeNameRu = firstResponse.machine.typeData.nameRU;
                    machineModel = firstResponse.machine.model;
                    for (const param of firstResponse.machine.params) {
                      if (param.spec.paramPurpose?.main) {
                        if (param.spec.type === 'range') {
                          assertIsDefined(param.valueRange); // value must exist
                          if (param.spec.unit) {
                            machineMainParam = `${param.valueRange}${param.spec.unit}`;
                          } else {
                            machineMainParam = `${param.valueRange}`;
                          }
                        }
                        if (param.spec.type === 'boolean') {
                          assertIsDefined(param.valueBoolean); // value must exist
                          if (param.valueBoolean) {
                            machineMainParam = `${param.spec.nameRU}`;
                          }
                        }
                        if (param.spec.type === 'select') {
                          assertIsDefined(param.valueSelect); // value must exist
                          machineMainParam = `${param.valueSelect}`;
                        }
                      }
                    }
                  }

                  this.fakeOrdersState.items.push({
                    id: fakeId,
                    machineTypeNameRu,
                    machineModel,
                    machineMainParam,
                    address,
                    avatarURL,
                    organizationName,
                    isVerified,
                    userFullName,
                    workDateTime,
                    workAmountUnit,
                    payment,
                    price,
                    orderDetailsQueryParams: {
                      entryId: fakeOrder.id,
                      viewAs: 'provider',
                      currentUserId: res.data.authCurrentUser.uuid,
                    },
                  });
                }
              }
            }
          }
          this.fakeOrdersState.page += 1;
          this.fakeOrdersState.pending = false;

          await this.fakeOrdersState.refreshTarget?.complete();
          this.fakeOrdersState.refreshTarget = undefined;
          await this.fakeOrdersState.infinityScrollTarget?.complete();
          this.fakeOrdersState.infinityScrollTarget = undefined;
          this.cdRef.markForCheck();
        },
      });
  }

  private translateWorkAmount(conditionWork: {
    type?: EnumEntryRequestsConditionsWorkType | null;
    value?: number | null;
  }) {
    const { type, value } = conditionWork;
    if (type && value) {
      return translateWorkAmount(type, value);
    }
    return '';
  }

  private translateWorkDateTime(
    conditionDate: { type?: EnumEntryRequestsConditionsDateType | null; value?: number | null },
    translateToday = false,
  ) {
    let workDateTime: string;
    let isWDToday = false;
    const { type, value } = conditionDate;
    const tsms = (value ?? 0) * 1000;
    if (type === 'none') {
      workDateTime = 'Дата обсуждаема';
    } else {
      const formattedDate = dayjs(tsms).format('YYYY-MM-DD');
      const formattedNow = dayjs().format('YYYY-MM-DD');
      isWDToday = formattedDate === formattedNow;
      if (translateToday && isWDToday) {
        workDateTime = 'Сегодня';
      } else {
        workDateTime = dayjs(tsms).format('DD MMMM (dd)');
      }
      if (type === 'datetime') {
        const formattedHours = dayjs(tsms).format('HH:mm');
        workDateTime = workDateTime + ' ' + formattedHours;
      }
    }
    return { workDateTime, isWDToday };
  }

  async onActiveMatchCardClick(ev: Event, idx: number) {
    ev.preventDefault();
    const match = this.activeMatchesState.items[idx];
    match.viewed = true;
    if (match.waitingForSign) {
      await this.router.navigate(['/entry-sign'], {
        queryParams: match.entryMatchDetailsQueryParams,
      });
    } else {
      const msg = {
        from: 'mobappV2',
        cmd: 'navigateToEntryMatchDetails',
        entryUuid: match.entryMatchDetailsQueryParams['entryId'],
      };
      this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
    }
  }

  async onInactiveMatchCardClick(ev: Event, idx: number) {
    ev.preventDefault();
    const match = this.inactiveMatchesState.items[idx];
    const msg = {
      from: 'mobappV2',
      cmd: 'navigateToEntryClosedDetails',
      entryUuid: match.entryClosedDetailsQueryParams['entryId'],
    };

    if (this.inactiveMatchesState.myMachineTypeIds.has(match.machineTypeId)) {
      this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
    } else {
      const alert = await this.alertController.create({
        message: `Добавьте ${match.machineTypeNameRu} в список своей техники, чтобы посмотреть заявку`,
        buttons: [
          {
            text: 'Добавить',
            handler: () => {
              this.router.navigate(['/profile-machine-add-edit']);
            },
          },
          {
            text: 'Отмена',
            role: 'cancel',
          },
        ],
      });
      await alert.present();
    }
  }
}
