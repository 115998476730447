import type * as Types from '../../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileTabAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileTabAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', firstname?: string | null, lastname?: string | null, id: string, avatar?: string | null, organizations: Array<{ __typename?: 'Organization', uuid: string, logo?: string | null, bases: Array<{ __typename?: 'Base', address: { __typename?: 'BaseAddress', fullAddress: string, coordinates: Array<number> } }>, date?: { __typename?: 'OrganizationDate', created?: number | null } | null, companies: Array<{ __typename?: 'OrganizationCompanies', name: string, isPrimary?: boolean | null, status?: Types.EnumOrganizationCompaniesStatus | null, date?: { __typename?: 'OrganizationCompaniesDate', founded?: number | null } | null, requisite2: { __typename?: 'OrganizationCompanyRequisite', inn: string } }>, users: Array<{ __typename?: 'OrganizationUsers', user: { __typename?: 'User', avatar?: string | null, firstname?: string | null, lastname?: string | null, phone: string, id: string } }>, machines: Array<{ __typename?: 'Machine', reg?: string | null, year?: number | null, verificationStatus?: Types.EnumMachineVerificationStatus | null, id: string, uuid: string, model: string, photos: Array<{ __typename?: 'MachinePhotos', src: string }>, availabilityStatus?: { __typename?: 'MachineAvailabilityStatus', status?: Types.EnumMachineAvailabilityStatusStatus | null, statusEnds?: any | null } | null, date?: { __typename?: 'MachineDate', available?: number | null } | null, typeData: { __typename?: 'MachineType', id: string, nameRU: string } } | null>, publicContact?: { __typename?: 'OrganizationPublicContact', phone?: string | null, user?: { __typename?: 'User', phone: string } | null } | null } | null> } | null };

export type ProfileTabAuthLogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileTabAuthLogoutMutation = { __typename?: 'Mutation', authLogout: { __typename?: 'OkResult', ok?: boolean | null } };

export const ProfileTabAuthCurrentUserDocument = gql`
    query ProfileTabAuthCurrentUser {
  authCurrentUser {
    firstname
    lastname
    id
    avatar
    organizations {
      uuid
      logo
      bases {
        address {
          fullAddress
          coordinates
        }
      }
      date {
        created
      }
      companies {
        name
        isPrimary
        status
        date {
          founded
        }
        requisite2 {
          inn
        }
      }
      users {
        user {
          avatar
          firstname
          lastname
          phone
          id
        }
      }
      machines {
        photos {
          src
        }
        reg
        year
        verificationStatus
        availabilityStatus {
          status
          statusEnds
        }
        date {
          available
        }
        id
        uuid
        model
        typeData {
          id
          nameRU
        }
      }
      publicContact {
        phone
        user {
          phone
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileTabAuthCurrentUserQueryGql extends Apollo.Query<ProfileTabAuthCurrentUserQuery, ProfileTabAuthCurrentUserQueryVariables> {
    override document = ProfileTabAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileTabAuthLogoutDocument = gql`
    mutation ProfileTabAuthLogout {
  authLogout {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileTabAuthLogoutMutationGql extends Apollo.Mutation<ProfileTabAuthLogoutMutation, ProfileTabAuthLogoutMutationVariables> {
    override document = ProfileTabAuthLogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }