<div class="card-box">
  <div class="machines">
    @for (machine of partnerMachines; track machine; let idx = $index) {
      <div
        class="machine"
        [class.hidden]="idx > 0 && !showAllMachines"
        (click)="showMachine(machine)"
      >
        <div class="machine-info" [class.full-width]="!machine.photo">
          <div class="machine-header">
            <div class="machine-category">{{ machine.categoryName }}</div>
            <span>{{ machine.mainParam }}</span>
          </div>
          <div class="machine-model">{{ machine.model }}, {{ machine.year }}г.</div>
          @if (getStatusText(machine)) {
            <div class="machine-status">
              <p class="status-txt">{{ getStatusText(machine) }}</p>
            </div>
          }
          <div class="machine-prices">
            <div class="machine-price">
              Мин. заказ:
              <span class="price-count">{{ machine.pricePerMinOrder || "-" }}&nbsp;₽</span>
            </div>
            <div class="machine-price">
              Цена за смену:
              <span class="price-count">{{ machine.pricePerWorkShift || "-" }}&nbsp;₽</span>
            </div>
          </div>
        </div>
        @if (machine.photo) {
          <div class="image-container">
            @if (machine.verificationStatus === "approved") {
              <ion-icon class="verified-icon" name="checkmark-circle"></ion-icon>
            }
            @if (machine.photo) {
              <div class="machine-photo">
                <img src="{{ machine.photo }}?w=300&h=300" />
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
  @if (partnerMachines.length > 1) {
    <div class="show-all-button-box">
      <ion-button
        class="show-all-button"
        (click)="showAllMachines = !showAllMachines"
        fill="outline"
      >
        {{ !showAllMachines ? "Показать ещё " + moreMachinesText : "Скрыть похожие машины" }}
      </ion-button>
    </div>
  }
  <div class="distance">
    <div class="distance-icon"><ion-icon name="location-sharp"></ion-icon></div>
    <div class="distance-text">{{ distanceToMachineBase }} км</div>
  </div>
  <hr />
  @if (user) {
    <div class="profile">
      <div class="header-content" (click)="showCompany()">
        <div class="header-info">
          @if (company) {
            <div class="company-name">{{ company.name }}</div>
          }
          <div class="profile-name">{{ user.firstname }} {{ user.lastname }}</div>
        </div>
        <ion-avatar class="profile-avatar">
          @if (user.avatar) {
            <img src="{{ user.avatar }}?w=300&h=300" alt="avatar" />
          }
          @if (!user.avatar) {
            <img src="../../../assets/avatar.svg" />
          }
        </ion-avatar>
      </div>
      <ion-buttons class="btn-wrapper">
        <ion-button fill="outline" class="select" (click)="sign()">
          <img src="../../../assets/icon/handshake.png" />
          Выбрать
        </ion-button>
        <ion-button class="call-me" (click)="callToProvider()">
          <ion-icon slot="start" name="call"></ion-icon>
          Позвонить
        </ion-button>
      </ion-buttons>
    </div>
  }
</div>
