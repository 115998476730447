import { register } from 'swiper/element/bundle';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayjsDurationPlugin from 'dayjs/plugin/duration';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import { appRoutes } from './app/app.routes';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { Api } from './app/apollo/api';
import { importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { YaCoreModule } from './app/yamapng/src/lib/core.module';
import { tokenInterceptor } from './app/token.interceptor';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloClientOptions } from '@apollo/client/core/ApolloClient';
import { HttpLink } from 'apollo-angular/http';
import { init as initApm } from '@elastic/apm-rum';
import { environment } from './environments/environment';
import { appInfo } from './app-info';

register();

dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsRelativeTimePlugin);
dayjs.extend(dayjsDurationPlugin);
dayjs.locale('ru');

let setCredentialsDone = false;

window.addEventListener('message', (event) => {
  const { data } = event;
  if (data.from !== 'mobappV1') {
    return;
  }
  if (data.cmd === 'setCredentials') {
    localStorage.setItem('MOBAPP_V2_BACKEND_URL', data.backendUrl);
    localStorage.setItem('MOBAPP_V2_AUTH_TOKEN', data.authToken);
    setCredentialsDone = true;
  }
});
// move app running to next task in order to handle
// setCredentials cmd before app starts
const intervalId = setInterval(async () => {
  if (setCredentialsDone) {
    clearInterval(intervalId);
    await main();
  }
}, 100);

if (environment.elasticApmServerUrl) {
  initApm({
    serviceName: appInfo.name,
    serviceVersion: appInfo.version,
    environment: environment.elasticApmEnvironment,
    serverUrl: environment.elasticApmServerUrl,
    serverUrlPrefix: '/W8DaUx280ciI4032',
    apiVersion: 3,
    distributedTracingOrigins: ['https://api.rental-club.ru', 'https://api.dev.rclb.ru'],
  });
}

const main = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      provideExperimentalZonelessChangeDetection(),
      provideHttpClient(withInterceptors([tokenInterceptor])),
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
      provideIonicAngular({
        mode: 'md',
        backButtonIcon: 'chevron-back',
        innerHTMLTemplatesEnabled: true,
      }),
      importProvidersFrom(
        ApolloModule,
        YaCoreModule.forRoot({
          apiKey: '95275d77-20a8-4d12-a9df-0ccff7271600',
        }),
      ),
      {
        provide: APOLLO_FLAGS,
        useValue: {
          useInitialLoading: true,
        },
      },
      {
        provide: APOLLO_OPTIONS,
        deps: [HttpLink], // HttpLink enables support for Interceptors
        useFactory: (httpLink: HttpLink): ApolloClientOptions<unknown> => {
          const backendProductionUrl = 'https://api.rental-club.ru';
          const url = localStorage.getItem('MOBAPP_V2_BACKEND_URL') || backendProductionUrl;
          const uri = url + '/v2/graphql';
          return {
            link: httpLink.create({ uri }),
            cache: new InMemoryCache(),
            defaultOptions: {
              query: {
                fetchPolicy: 'no-cache',
              },
              watchQuery: {
                fetchPolicy: 'no-cache',
              },
              mutate: {
                fetchPolicy: 'no-cache',
              },
            },
          };
        },
      },
      Api,
    ],
  }).catch((err) => console.log(err));
