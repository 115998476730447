import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IonButton,
  IonChip,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { EntriesTabSearchEntryRentListQueryGql } from './entries-tab.component.gql-gen';
import { Subscription } from 'rxjs';
import { assertIsDefined, translateWorkAmount } from '../../utils';
import dayjs from 'dayjs';
import {
  EntryRentResultItemAvailableAction,
  EntryRentResultItemStatus,
} from '../../../base-types.gql-gen';
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@ionic/angular';
import { Router, RouterLink } from '@angular/router';

type EntryRentListItemUIData = {
  id: string;
  // uuid: string;
  machineTypeNameRu: string;
  workDateTime: string;
  workAmount: string;
  objects: { title: string; address: string }[];
  authorFullName: string;
  createdAt: string;
  comment: string;
  proposeNumber: number;
  status: EntryRentResultItemStatus;
  availableActions: EntryRentResultItemAvailableAction[];
};

@Component({
  selector: 'app-entries-tab',
  templateUrl: 'entries-tab.component.html',
  styleUrls: ['entries-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonList,
    IonItem,
    IonText,
    IonChip,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    RouterLink,
  ],
})
export class EntriesTabComponent implements OnInit {
  protected entryRentListState = {
    pending: false,
    hasNext: true,
    skip: 0,
    items: [] as EntryRentListItemUIData[],
    sub: undefined as Subscription | undefined,
    refreshTarget: undefined as HTMLIonRefresherElement | undefined,
    infinityScrollTarget: undefined as HTMLIonInfiniteScrollElement | undefined,
  };

  private authCurrentUserUuid = '';

  constructor(
    private searchEntryRentListQueryGql: EntriesTabSearchEntryRentListQueryGql,
    private cdRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadEntryRentList('new');
  }

  private loadEntryRentList(action: 'new' | 'next') {
    const BATCH_SIZE = 10;
    if (action === 'new') {
      this.entryRentListState.skip = 0;
      this.entryRentListState.hasNext = true;
      this.entryRentListState.items = [];
    }
    if (!this.entryRentListState.hasNext) {
      return;
    }
    this.entryRentListState.pending = true;
    this.entryRentListState.sub?.unsubscribe();
    this.entryRentListState.sub = this.searchEntryRentListQueryGql
      .fetch({
        skip: this.entryRentListState.skip,
        limit: BATCH_SIZE,
      })
      .subscribe({
        next: async (res) => {
          assertIsDefined(res.data.searchEntryRentList);
          assertIsDefined(res.data.authCurrentUser);
          if (res.data.searchEntryRentList.items.length < BATCH_SIZE) {
            this.entryRentListState.hasNext = false;
          }
          this.entryRentListState.skip += BATCH_SIZE;
          this.entryRentListState.pending = false;

          for (const entry of res.data.searchEntryRentList.items) {
            const {
              status,
              id,
              comment,
              createdAt,
              objects,
              author,
              proposeNumber,
              machineType,
              workType,
              workStartDateType,
              workStartDate,
              workValue,
              availableActions,
            } = entry;
            const uiObjects = [] as EntryRentListItemUIData['objects'];
            for (const obj of objects) {
              uiObjects.push({
                title: obj.title ?? '',
                address: obj.fullAddress ?? '',
              });
            }
            let workDateTime = '';
            if (workStartDateType === 'date') {
              workDateTime = dayjs(workStartDate).format('D MMMM (dd)');
            } else if (workStartDateType === 'datetime') {
              workDateTime = dayjs(workStartDate).format('D MMMM (dd), H:mm');
            } else if (workStartDateType === 'none') {
              workDateTime = 'Дата обсуждаема';
            }

            let workAmount = '';
            if (workType && workValue) {
              workAmount = translateWorkAmount(workType, workValue);
            }
            this.entryRentListState.items.push({
              id,
              authorFullName: `${author.firstname} ${author.lastname}`,
              comment: `${comment}`,
              createdAt: dayjs(createdAt).format('HH:mm DD.MM.YY'),
              machineTypeNameRu: machineType.nameRu,
              objects: uiObjects,
              status,
              proposeNumber,
              workAmount,
              workDateTime,
              availableActions,
            });
          }

          await this.entryRentListState.refreshTarget?.complete();
          this.entryRentListState.refreshTarget = undefined;
          await this.entryRentListState.infinityScrollTarget?.complete();
          this.entryRentListState.infinityScrollTarget = undefined;

          this.cdRef.markForCheck();
          this.authCurrentUserUuid = res.data.authCurrentUser.uuid;
        },
        error: () => {},
      });
  }

  async onEntryRentListInfiniteScrollInfinite(ev: InfiniteScrollCustomEvent) {
    ev.preventDefault();
    await this.entryRentListState.infinityScrollTarget?.complete();
    this.entryRentListState.infinityScrollTarget = ev.target;
    this.loadEntryRentList('next');
  }

  async onEntryRentListRefresh(ev: RefresherCustomEvent) {
    ev.preventDefault();
    await this.entryRentListState.refreshTarget?.complete();
    this.entryRentListState.refreshTarget = ev.target;
    this.loadEntryRentList('new');
  }

  async onRentListItemClick(ev: Event, idx: number) {
    ev.preventDefault();
    const entryRent = this.entryRentListState.items.at(idx);
    assertIsDefined(entryRent);
    if (entryRent.availableActions.includes('SEE_ENTRY_DETAILS')) {
      if (entryRent.status === 'signed') {
        const queryParams = {
          entryId: entryRent.id,
          currentUserId: this.authCurrentUserUuid,
          viewAs: 'customer',
        };
        await this.router.navigate(['/entry-sign'], { queryParams });
      } else if (entryRent.status === 'cancelled') {
        // do nothing witch cancelled entries
      } else {
        const queryParams = {
          entryId: entryRent.id,
        };
        await this.router.navigate(['/entry-details-machine'], { queryParams });
      }
    }
  }
}
