import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ChaportLoaderService } from './chaport-loader.service';
import { callPhoneNumber, SUPPORT_PHONE_NUMBER } from '../../utils';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-support-tab',
  templateUrl: 'support-tab.component.html',
  styleUrls: ['support-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonSpinner,
    RouterLink,
    IonText,
  ],
})
export class SupportTabComponent implements OnInit {
  constructor(
    private cdRef: ChangeDetectorRef,
    private chaportLoader: ChaportLoaderService,
  ) {}

  async ngOnInit() {
    const selector = '#support-tab-chat-container';
    await this.chaportLoader.openIn({ selector });
    this.cdRef.detectChanges();
  }

  onSupportBtnClick(ev: Event) {
    ev.preventDefault();
    callPhoneNumber(SUPPORT_PHONE_NUMBER);
  }
}
