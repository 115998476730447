import type * as Types from '../../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntriesTabSearchEntryRentListQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  skip: Types.Scalars['Int']['input'];
}>;


export type EntriesTabSearchEntryRentListQuery = { __typename?: 'Query', searchEntryRentList: { __typename?: 'EntryRentResult', items: Array<{ __typename?: 'EntryRentResultItem', id: string, createdAt: any, workStartDateType: Types.EntryRentResultItemWorkStartDateType, workStartDate?: any | null, workType: Types.EntryRentResultItemWorkType, workValue: number, comment?: string | null, status: Types.EntryRentResultItemStatus, proposeNumber: number, availableActions: Array<Types.EntryRentResultItemAvailableAction>, author: { __typename?: 'EntryRentResultItemAuthor', firstname: string, lastname?: string | null }, machineType: { __typename?: 'EntryRentResultItemMachineType', nameRu: string }, objects: Array<{ __typename?: 'EntryRentResultItemObject', fullAddress: string, title?: string | null }> }> }, authCurrentUser?: { __typename?: 'User', uuid: string } | null };

export const EntriesTabSearchEntryRentListDocument = gql`
    query EntriesTabSearchEntryRentList($limit: Int!, $skip: Int!) {
  searchEntryRentList(limit: $limit, skip: $skip) {
    items {
      id
      author {
        firstname
        lastname
      }
      createdAt
      machineType {
        nameRu
      }
      workStartDateType
      workStartDate
      workType
      workValue
      objects {
        fullAddress
        title
      }
      comment
      status
      proposeNumber
      availableActions
    }
  }
  authCurrentUser {
    uuid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntriesTabSearchEntryRentListQueryGql extends Apollo.Query<EntriesTabSearchEntryRentListQuery, EntriesTabSearchEntryRentListQueryVariables> {
    override document = EntriesTabSearchEntryRentListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }