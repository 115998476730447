<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="back-button" (click)="close()">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="main-title">
      {{ edit ? "Редактирование заявки" : "Детали заявки" }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  @if (updatedData) {
    <app-fields-edit
      #fieldsEdit
      [class.disabled]="!edit"
      [entry]="entry"
      [updatedData]="updatedData"
      [isKeyboardOpen]="isKeyboardOpen"
      [edit]="edit"
    ></app-fields-edit>
  }
</ion-content>
<ion-footer no-border [hidden]="isKeyboardOpen">
  <ion-toolbar no-padding no-safe-area>
    <div class="box">
      <ion-button class="button-action" expand="block" [class.edit]="!edit" (click)="updateEntry()">
        {{ !edit ? "Редактировать" : "Сохранить" }}
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
