import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Api } from '../apollo/api';
import { ActivatedRoute } from '@angular/router';
import * as translations from '../../interface.json';
import { Entry, EntryProposes, Maybe } from '../../base-types.gql-gen';
import {
  ActionSheetController,
  IonApp,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import { ProvidersListComponent } from './providers-list/providers-list.component';
import { EntryEditModalComponent } from '../entry-edit-modal/entry-edit-modal.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { ProposeCardComponent } from './proposes-card/propose-card.component';
import dayjs from 'dayjs';
import { RefresherCustomEvent } from '@ionic/angular';

@Component({
  selector: 'app-entry-details-machine',
  templateUrl: './entry-details-machine.component.html',
  styleUrls: ['./entry-details-machine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonApp,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonProgressBar,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    CancelModalComponent,
    ProposeCardComponent,
    ProvidersListComponent,
  ],
})
export class EntryDetailsMachineComponent implements OnInit {
  @ViewChild(ProvidersListComponent)
  private providersListComponent!: ProvidersListComponent;
  currentPage = 'proposes';
  loading = true;
  entry!: Entry;
  searchStarted = false;
  proposeForSign?: Maybe<EntryProposes>;
  hasPropose = false;
  // todo: move to service and reuse across all components
  maybeMobappV1Window = window.parent;
  progress = 0;

  constructor(
    private api: Api,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private actionSheetCtrl: ActionSheetController,
  ) {}

  ngOnInit() {
    document.body.classList.remove('dark');
    const entryId = this.route.snapshot.queryParams['entryId'] || '';
    this.api
      .watchEntryById(entryId)
      .refetch()
      .then(({ data }: any) => {
        this.loading = false;
        this.entry = data?.entry;
        this.searchStarted = this.entry?.status === 'lookup';
        if (this.searchStarted) {
          this.timerStart();
          this.progress = 0.3;
        }
        this.cd.markForCheck();
      });
  }

  sortedProposes() {
    const proposes = [...(this.entry?.proposes || [])];
    return proposes.sort((a, b) => {
      return this.isSelected(this.entry.proposes, a)
        ? -1
        : this.isSelected(this.entry.proposes, b)
          ? 1
          : 0;
    });
  }

  isSelected(proposes?: Maybe<Array<Maybe<EntryProposes>>>, propose?: Maybe<EntryProposes>) {
    const customerPropose = proposes?.find((p) => p?.initiator === 'customer');
    const org = propose?.responses && propose?.responses[0]?.organization;
    return !!customerPropose?.responses?.find((r) => r?.organization?.uuid === org?.uuid);
  }

  onShowCancelModal(propose: Maybe<EntryProposes>, hasPropose = false) {
    this.proposeForSign = propose;
    this.hasPropose = hasPropose;
  }

  async closeAndRefresh(refresh = false) {
    this.proposeForSign = undefined;
    this.hasPropose = false;
    if (refresh) {
      await this.refreshPage();
    }
  }

  @HostListener('window:message', ['$event'])
  async onWindowMessage(ev: MessageEvent) {
    if (ev.data?.cmd === 'refresh') {
      this.refreshPage();
    }
  }

  async refreshPage() {
    this.loading = true;
    const { data } = await this.api.watchEntryById(this.entry?.uuid).refetch();
    this.entry = data?.entry as any;
    this.loading = false;
    this.providersListComponent?.resetProviders(1, 10);
    this.cd.detectChanges();
  }

  get entryCreatedTimer(): string {
    return dayjs
      .unix(this.entry?.date?.created || 0)
      .locale('ru')
      .fromNow();
  }

  get entryCategoryName(): string {
    const data = this.entry?.requests && this.entry?.requests[0]?.data?.machineTypeData;
    // @ts-ignore
    return translations[data.name];
  }

  get entryAddress() {
    if (this.entry.objects) {
      const title = this.entry.objects[0]?.title ? this.entry.objects[0]?.title + ', ' : '';
      return title + this.entry.objects[0]?.address?.fullAddress || '';
    }
    return '';
  }

  get startDateTxt() {
    if (this.entry?.requests && this.entry?.requests[0]?.conditions?.date?.type !== 'none') {
      return dayjs
        .unix((this.entry?.requests && this.entry?.requests[0]?.conditions?.date?.value) || 0)
        .locale('ru')
        .format('DD MMMM YYYY');
    }
    return 'Дата обсуждаема';
  }

  trackByForProposes(_index: any, item: any) {
    return item.uuid;
  }

  onBackButtonClick() {
    const msg = { from: 'mobappV2', cmd: 'goBack' };
    this.maybeMobappV1Window.postMessage(msg, '*');
  }

  onSegmentChange(e: any) {
    this.currentPage = e.detail.value || '';
  }

  async onProviderListRefresh(e: RefresherCustomEvent) {
    await this.refreshPage();
    await e.target.complete();
  }

  handleScroll() {
    const msg = { from: 'mobappV2', cmd: 'close-keyboard' };
    window.parent.postMessage(msg, '*');
  }

  onSearchStart() {
    this.searchStarted = true;
    const msg = {
      from: 'mobappV2',
      cmd: 'status-change',
      info: { entry: this.entry, status: 'lookup' },
    };
    this.maybeMobappV1Window.postMessage(msg, '*');
    this.timerStart();
  }

  async openEntryInfo() {
    const modal = await this.modalCtrl.create({
      component: EntryEditModalComponent,
      componentProps: { entry: this.entry },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.refreshPage();
      // this.handleRefresh();
    }
  }

  async closeEntry() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Вы уверены что хотите завершить заявку?',
      buttons: [
        {
          text: 'Да',
          role: 'confirm',
        },
        {
          text: 'Нет',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const { role } = await actionSheet.onWillDismiss();
    if (role === 'confirm') {
      this.loading = true;
      this.cd.markForCheck();
      await this.api.cancelEntry(this.entry.ref);
      setTimeout(() => {
        this.loading = false;
        const msg = { from: 'mobappV2', cmd: 'close-entry', entry: this.entry };
        this.maybeMobappV1Window.postMessage(msg, '*');
      }, 3500);
    }
  }

  timerStart() {
    setInterval(() => {
      this.progress += 0.01;
      this.cd.markForCheck();
      if (this.progress > 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 1000);
      }
    }, 500);
  }
}
