import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type WelcomePageAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WelcomePageAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', firstname?: string | null } | null };

export const WelcomePageAuthCurrentUserDocument = gql`
    query welcomePageAuthCurrentUser {
  authCurrentUser {
    firstname
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WelcomePageAuthCurrentUserQueryGql extends Apollo.Query<WelcomePageAuthCurrentUserQuery, WelcomePageAuthCurrentUserQueryVariables> {
    override document = WelcomePageAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }