import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  LoadingController,
  ToastController,
} from '@ionic/angular/standalone';
import {
  ProfileTabAuthCurrentUserQuery,
  ProfileTabAuthCurrentUserQueryGql,
  ProfileTabAuthLogoutMutationGql,
} from './profile-tab.gql-gen';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { MachineVerificationShelfComponent } from '../../machine-verification-shelf/machine-verification-shelf.component';
import { assertIsDefined, callPhoneNumber, PHONE_NUMBER_RU_MASK } from '../../utils';
import { AlertButton, InfiniteScrollCustomEvent } from '@ionic/angular';
import { ApolloQueryResult } from '@apollo/client';
import { MaskitoOptions } from '@maskito/core';
import { MaskitoPipe } from '@maskito/angular';
import { MachineAvailabilityStatusShelfComponent } from '../../machine-availability-status-shelf/machine-availability-status-shelf.component';
import {
  SearchAddressSelectEvent,
  SearchAddressWithMapModalComponent,
} from '../../search-address-with-map-modal/search-address-with-map-modal.component';
import { Api } from '../../apollo/api';
import { RC_MACHINE_UPDATED } from '../../custom-events';

type CompanyUIData = NonNullable<
  NonNullable<ProfileTabAuthCurrentUserQuery['authCurrentUser']>['organizations'][number]
>['companies'][number] & {
  organizationLogo: string;
  dateFromNow: string;
  publicPhone: string;
};

type UserUIData = {
  id: string;
  avatar: string;
  firstname: string;
  lastname: string;
  phone: string;
};

type MachineUIData = NonNullable<
  NonNullable<ProfileTabAuthCurrentUserQuery['authCurrentUser']>['organizations'][number]
>['machines'][number] & {
  firstPhoto: string;
};

@Component({
  selector: 'app-profile-tab',
  templateUrl: 'profile-tab.component.html',
  styleUrls: ['profile-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItem,
    IonAvatar,
    IonText,
    IonButton,
    IonIcon,
    IonNote,
    IonCard,
    IonThumbnail,
    RouterLink,
    MachineVerificationShelfComponent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    MaskitoPipe,
    MachineAvailabilityStatusShelfComponent,
    IonAlert,
    IonSpinner,
    IonModal,
    SearchAddressWithMapModalComponent,
  ],
})
export class ProfileTabComponent implements OnInit, OnDestroy {
  selectedSegment = 'info';

  protected companyData?: CompanyUIData;
  protected myUserData?: UserUIData;
  protected machineBaseData?: SearchAddressSelectEvent;
  protected otherUsersData: UserUIData[] = [];
  protected machinesData: MachineUIData[] = [];
  protected organizationUuid = '';
  protected renderedMachinesData: MachineUIData[] = [];
  protected isAddressModalOpen = false;
  protected phoneNumberMask: MaskitoOptions = {
    mask: PHONE_NUMBER_RU_MASK,
  };

  protected logoutAlertBtns: AlertButton[] = [
    {
      text: 'Отмена',
    },
    {
      text: 'ОК',
      role: 'destructive',
      handler: async () => {
        this.authLogoutMutationGql.mutate().subscribe({
          next: async (res) => {
            if (res.data?.authLogout.ok) {
              await this.router.navigate(['/sign-in']);
            } else {
              await this.showToast('Произошла ошибка, наши сервера сломались');
            }
          },
          error: async () => {
            await this.showToast('Произошла ошибка, наши сервера сломались');
          },
        });
      },
    },
  ];

  private routeSub?: Subscription;
  private dataSub?: Subscription;
  private reloadOnNavigation = true;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private api: Api,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private authCurrentUserQueryGql: ProfileTabAuthCurrentUserQueryGql,
    private authLogoutMutationGql: ProfileTabAuthLogoutMutationGql,
  ) {}

  ngOnInit() {
    // todo: sync with selectedSegment
    this.routeSub = this.activatedRoute.queryParams.subscribe((params) => {
      if (params['segment'] === 'machines' || params['segment'] === 'info') {
        this.selectedSegment = params['segment'];
        this.cdRef.markForCheck();
      }
      this.loadAuthCurrentUserData(this.reloadOnNavigation);
      this.reloadOnNavigation = false;
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
    this.dataSub?.unsubscribe();
  }

  @HostListener(`window:${RC_MACHINE_UPDATED}`)
  onRcMachineUpdated() {
    this.reloadOnNavigation = true;
  }

  onSegmentChange(event: CustomEvent) {
    this.selectedSegment = event.detail.value;
  }

  async onMachineListInfinite(ev: InfiniteScrollCustomEvent) {
    this.renderModerMachines();
    await ev.target.complete();
  }

  private renderModerMachines() {
    const numberToRender = 8;
    for (let i = 0; i <= numberToRender; i += 1) {
      const idx = this.renderedMachinesData.length;
      if (this.machinesData[idx]) {
        this.renderedMachinesData.push(this.machinesData[idx]);
      }
    }
  }

  private loadAuthCurrentUserData(force = false) {
    if (!this.dataSub || force) {
      this.dataSub?.unsubscribe();

      this.myUserData = undefined;
      this.companyData = undefined;
      this.machineBaseData = undefined;
      this.otherUsersData = [];
      this.machinesData = [];
      this.renderedMachinesData = [];
      this.organizationUuid = '';
      this.cdRef.markForCheck();

      this.dataSub = this.authCurrentUserQueryGql.fetch().subscribe((res) => {
        this.handleAuthCurrentUserDataChange(res);
        this.cdRef.detectChanges();
      });
    }
  }

  private handleAuthCurrentUserDataChange(res: ApolloQueryResult<ProfileTabAuthCurrentUserQuery>) {
    const { authCurrentUser } = res.data;
    assertIsDefined(authCurrentUser);

    this.myUserData = {
      id: authCurrentUser.id,
      firstname: authCurrentUser.firstname ?? '',
      lastname: authCurrentUser.lastname ?? '',
      avatar: authCurrentUser.avatar ?? '',
      phone: '',
    };

    for (const org of authCurrentUser.organizations) {
      if (!org) {
        continue;
      }
      this.organizationUuid = org.uuid;
      let date = '';
      if (org.date?.created) {
        date = dayjs(org.date.created * 1000).fromNow(true);
      }

      for (const comp of org.companies ?? []) {
        if (!this.companyData || comp.isPrimary) {
          this.companyData = {
            ...comp,
            organizationLogo: org.logo ?? '',
            dateFromNow: date,
            publicPhone: org.publicContact?.phone ?? org.publicContact?.user?.phone ?? '',
          };
        }
        if (comp.isPrimary) {
          break;
        }
      }

      for (const b of org.bases ?? []) {
        if (b?.address?.fullAddress) {
          this.machineBaseData = {
            fullAddress: b.address.fullAddress,
            // BE stores coordinates in [Longitude, Latitude]
            coords: b.address.coordinates.reverse(),
          };
        }
      }

      for (const u of org?.users ?? []) {
        if (u.user.id !== authCurrentUser.id) {
          this.otherUsersData.push({
            id: u.user.id,
            avatar: u.user.avatar ?? '',
            lastname: u.user.lastname ?? '',
            firstname: u.user.firstname ?? '',
            phone: u.user.phone,
          });
        }
      }

      for (const m of org?.machines ?? []) {
        if (m) {
          this.machinesData.push({
            ...m,
            firstPhoto: m.photos[0]?.src ?? '',
          });
        }
      }
    }
    this.machinesData.sort((a, b) => {
      if (a.typeData.nameRU > b.typeData.nameRU) {
        return 1;
      }
      if (a.typeData.nameRU < b.typeData.nameRU) {
        return -1;
      }
      return 0;
    });
    this.renderModerMachines();
  }

  onBtnCallClick(ev: Event, user: UserUIData) {
    ev.preventDefault();
    callPhoneNumber(user.phone);
  }

  onMachineBaseClick(ev: Event) {
    ev.preventDefault();
    this.isAddressModalOpen = true;
  }

  onSearchAddressModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isAddressModalOpen = false;
  }

  onSearchAddressModalClose() {
    setTimeout(() => {
      //run in new macrotask, because stupid bug with changeDetection
      this.isAddressModalOpen = false;
      this.cdRef.detectChanges();
    });
  }

  async onSearchAddressModalSelect(ev: SearchAddressSelectEvent) {
    const loading = await this.loadingCtrl.create({
      message: 'Обновляем место базирования техники',
    });
    await loading.present();
    try {
      const address = {
        fullAddress: ev.fullAddress,
        //for BE coords: [Longitude, Latitude]
        coordinates: ev.coords.reverse(),
      };
      const res = await this.api.setMachineBase(address, this.organizationUuid);
      this.machineBaseData = {
        fullAddress: res.address.fullAddress,
        //for BE coords: [Longitude, Latitude]
        coords: res.address.coordinates.reverse(),
      };
    } catch (_err) {
      await this.showToast('Произошла ошибка, наши сервера сломались');
    } finally {
      this.isAddressModalOpen = false;
      this.cdRef.markForCheck();
      await loading.dismiss();
    }
  }

  private async showToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 3000,
    });
    await toast.present();
  }
}
