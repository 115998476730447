<ion-header>
  <ion-toolbar>
    <ion-title color="dark">Заявки на технику</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-refresher
    [disabled]="entryRentListState.pending"
    (ionRefresh)="onEntryRentListRefresh($event)"
    slot="fixed"
  >
    <ion-refresher-content
      pullingIcon="chevron-down-circle-outline"
      pullingText="Потянуть для обновления"
      refreshingSpinner=""
      refreshingText="Обновляем список..."
    ></ion-refresher-content>
  </ion-refresher>

  <ion-button
    [routerLink]="'/entry-request-form'"
    color="rcyellow"
    size="large"
    expand="block"
    class="ion-padding"
  >
    Новая заявка
  </ion-button>
  @if (entryRentListState.pending && entryRentListState.items.length === 0) {
    <div class="spinner-wrapper">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  }
  <ion-list class="ion-no-padding">
    @for (entryRent of entryRentListState.items; track entryRent.id) {
      <ion-item
        (click)="onRentListItemClick($event, $index)"
        [class.canceled-request-item]="entryRent.status === 'cancelled'"
        class="request-item"
      >
        <div class="item-content">
          <div class="item-name-date">
            <ion-text>{{ entryRent.authorFullName }}</ion-text>
            <ion-text>{{ entryRent.createdAt }}</ion-text>
          </div>
          <h4 class="ion-no-margin">{{ entryRent.machineTypeNameRu }}</h4>
          <ion-text class="paragraph-medium">
            <span>{{ entryRent.workDateTime }}&nbsp;</span>
            <span>{{ entryRent.workAmount }}</span>
          </ion-text>
          <ion-text class="regular-text">
            @for (obj of entryRent.objects; track $index; let last = $last) {
              @if (obj.title) {
                <span>{{ obj.title }},</span>
              }
              @if (last) {
                <span>{{ obj.address }}</span>
              } @else {
                <span>{{ obj.address }}&nbsp;→</span>
              }
            }
          </ion-text>
          <ion-text class="regular-text text-comment">
            {{ entryRent.comment }}
          </ion-text>
          @if (entryRent.status === "signed") {
            <ion-chip color="rcgreen">Подписана</ion-chip>
          } @else if (entryRent.status === "cancelled") {
            <ion-chip color="medium">Отменена</ion-chip>
          } @else if (entryRent.proposeNumber) {
            <ion-chip color="primary">Предложения:&nbsp;{{ entryRent.proposeNumber }}</ion-chip>
          }
        </div>
      </ion-item>
    }
  </ion-list>
  @if (entryRentListState.hasNext) {
    <ion-infinite-scroll (ionInfinite)="onEntryRentListInfiniteScrollInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
</ion-content>
