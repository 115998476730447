@if (loading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
<ion-searchbar placeholder="Поиск" (ionChange)="search($event)"></ion-searchbar>
@if (providersForView.length) {
  @if (searchText && searchStarted) {
    <div class="search-text">{{ searchText }}</div>
  }
  @for (provider of providersForView; track trackByForProviders($index, provider)) {
    <app-provider-card
      [entry]="entry"
      [autoSearchResult]="provider"
      (showCancelModal)="onShowCancelModal($event)"
    ></app-provider-card>
  }
  <ion-infinite-scroll
    (ionInfinite)="onIonInfinite($event)"
    [disabled]="page >= maxPages || searchStarted"
  >
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
}
@if (!loading && !providersForView.length) {
  <div class="search-text">
    Поиск исполнителей по вашему запросу не дал результатов. Попробуйте изменить данные в заявке или
    пригласить исполнителя
  </div>
}
@if ((!loading && !providersForView.length) || (!loading && page === maxPages)) {
  <ion-button fill="outline" class="ion-margin" (click)="btnInvite_Click()">
    Пригласить исполнителя
  </ion-button>
}
